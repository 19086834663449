import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { Container, GridContainer } from "@util/standard";
import Preview from "@components/shared/preview";

function PeoplesGridDisplay() {
  const { allSanityPeople }: Query = useStaticQuery(graphql`
    {
      allSanityPeople(sort: { fields: _createdAt, order: ASC }) {
        edges {
          node {
            slug {
              current
            }
            role
            name
            _id
            description
            featureImage {
              asset {
                fluid(maxWidth: 1000) {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  if (allSanityPeople == null) {
    return null;
  }

  return (
    <Container margin="0 -3px 0 -3px">
      <GridContainer
        gridTemplateColumns="repeat(3,1fr)"
        margin="0 auto"
        tabletRepeat={1}
      >
        {allSanityPeople.edges.map((person) => {
          const { name, description, featureImage, slug, role } = person.node;
          if (person == null) {
            return null;
          }
          return (
            <Preview
              name={name}
              description={description}
              role={role}
              slug={slug}
              featureImage={featureImage}
              key={person.node._id}
            />
          );
        })}
      </GridContainer>
    </Container>
  );
}

export default PeoplesGridDisplay;
