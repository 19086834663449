import { Container, P } from "@util/standard";
import React, { useContext, useState } from "react";
import { BlocksContent } from "../sub";
import styled from "styled-components";
import { colors, TABLET_BREAKPOINT } from "@util/constants";
import { SanityYourStoriesCta } from "@graphql-types";
import ModalBox from "../modalBox";
import YourStoriesForm from "./yourStoriesForm";
import ScreenWidthProvider, { ScreenSizeContext } from "../screenWidthProvider";

interface Props {
  content: SanityYourStoriesCta;
}

const TextWrapper = styled.div`
  text-align: center;
  h2,
  h3,
  h4 {
    font-size: 20px;
    font-family: "bold";
    text-transform: uppercase;
    color: ${colors.grey};
    margin-bottom: 15px;
  }
`;

const PopUpTextWrapper = styled.div`
  width: 56%;
  margin: auto;

  h2 {
    max-width: 275px;
    font-size: 50px;
    font-family: "extra-bold";
    color: ${colors.grey};
    margin-bottom: 20px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding-top: 10%;
    width: 90%;

    h2 {
      font-size: 30px;
    }
    p {
      font-size: 14px;
    }
  }
`;

const ButtonWrapper = styled.div<{ isDark?: boolean }>`
  border: 1.5px solid ${colors.darkGrey};
  border-radius: 20px;
  padding: 0px 10%;
  text-align: center;
  width: max-content;
  margin: 30px auto;
  cursor: pointer;
  opacity: 1;
  &:hover {
    opacity: 0.5;
  }
  transition: opacity 0.2s;
`;

function YourStoriesCta({ content }: Props) {
  const [popUpVisible, setPopUpVisible] = useState(false);
  const { screenSize } = useContext(ScreenSizeContext);
  if (content == null || content.ctaTextBlock == null) {
    return null;
  }

  if (content.buttonText == null) {
    return null;
  }

  return (
    <ScreenWidthProvider>
      <Container
        height="100%"
        width="30%"
        margin="200px auto"
        tabletWidth="80%"
        mobileWidth="88%"
      >
        <TextWrapper>
          <BlocksContent blocks={content.ctaTextBlock._rawContent} />
          <ButtonWrapper onClick={() => setPopUpVisible(true)}>
            <P color="black" fontSize={15} margin="8px 0px">
              {content.buttonText.toUpperCase()}
            </P>
          </ButtonWrapper>
          <ModalBox
            visible={popUpVisible}
            setVisible={setPopUpVisible}
            width="95%"
            background="white"
            padding="0px"
            overflowX="hidden"
          >
            <Container
              display="flex"
              isMobileColumn
              padding="0px"
              height="100%"
            >
              <Container
                display="flex"
                justifyContent="center"
                width="40%"
                tabletWidth="100%"
                backgroundColor="white"
              >
                <PopUpTextWrapper>
                  <BlocksContent blocks={content.popUpText._rawContent} />
                </PopUpTextWrapper>
              </Container>
              <Container
                width="60%"
                height="100%"
                tabletWidth="100%"
                backgroundColor="beige"
              >
                <YourStoriesForm />
              </Container>
            </Container>
          </ModalBox>
        </TextWrapper>
      </Container>
    </ScreenWidthProvider>
  );
}

export default YourStoriesCta;
