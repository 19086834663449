import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Container, GridContainer, H3 } from "@util/standard";
import React, { useState } from "react";
import styled from "styled-components";

const FormContainer = styled(Container)<{ formSubmitted: boolean }>`
  height: 100%;
  display: flex;
  justify-content: center;

  ${({ formSubmitted }) =>
    formSubmitted &&
    `
    padding: 0px;`}
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 8%;
    ${({ formSubmitted }) =>
      formSubmitted &&
      `
    padding: 39% 17%;`}
  } ;
`;

const StyledForm = styled.form`
  width: 69%;
  margin: auto;
`;

const FieldInput = styled.input`
  width: 100%;
  border: none;
  color: #565656;
  border-bottom: 1px solid #959595;
  background: transparent;
  padding-bottom: 15px;
  margin-bottom: 50px;
  ::placeholder {
    color: #565656;
    font-size: 14px;
  }

  :focus {
    outline: none;
  }
`;

const TextArea = styled.textarea`
  background: transparent;
  color: #565656;
  width: 100%;
  height: 30vh;
  border: 1px solid #959595;

  ::placeholder {
    font-family: "regular";
    color: #565656;
    font-size: 14px;
    padding: 15px;
  }
  :focus {
    outline: none;
  }
`;

const SubmitButton = styled.button<{ isDark?: boolean }>`
  color: white;
  border: none;
  font-size: 15px;
  background: ${colors.navy};
  border-radius: 30px;
  padding: 15px 50px;
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
  opacity: 1;
  &:hover {
    opacity: 0.5;
  }
  transition: opacity 0.2s;
`;

const SuccessMsg = styled(H3)`
  text-align: center;
  color: ${colors.grey};
  width: 80%;
  margin: auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

function YourStoriesForm() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const successMessage = "Thank you for submitting your story!";

  const [formData, updateFormData] = useState({
    FNAME: "",
    LNAME: "",
    email_address: "",
    role: "",
    STORY: "",
  });

  const notifyAdmin = async (e) => {
    const postData = {
      email_address: formData.email_address,
      status: "unsubscribed",
      tags: ["Your Stories"],
      merge_fields: {
        FNAME: formData.FNAME,
        LNAME: formData.LNAME,
        ROLE: formData.role,
        STORY: formData.STORY,
      },
    };

    e.preventDefault();
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(postData),
    };

    fetch(
      `${process.env.GATSBY_BASE_URL}.netlify/functions/emailAdmin`,
      options
    )
      .then(function (response) {
        setFormSubmitted(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    const postData = {
      email_address: formData.email_address,
      status: "unsubscribed",
      tags: ["Your Stories"],
      merge_fields: {
        FNAME: formData.FNAME,
        LNAME: formData.LNAME,
        ROLE: formData.role,
        STORY: formData.STORY,
      },
    };

    e.preventDefault();
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(postData),
    };

    fetch(
      `${process.env.GATSBY_BASE_URL}.netlify/functions/yourStories`,
      options
    )
      .then(function (response) {
        notifyAdmin(e);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <FormContainer formSubmitted={formSubmitted}>
      {!formSubmitted && (
        <StyledForm onSubmit={handleSubmit}>
          <GridContainer gridTemplateColumns="repeat(2,1fr)" columnGap={25}>
            <FieldInput
              placeholder="FIRST NAME"
              required
              onChange={(e) =>
                updateFormData({ ...formData, FNAME: e.target.value })
              }
            />
            <FieldInput
              placeholder="LAST NAME"
              required
              onChange={(e) =>
                updateFormData({ ...formData, LNAME: e.target.value })
              }
            />
          </GridContainer>
          <FieldInput
            placeholder="EMAIL"
            required
            type="email"
            onChange={(e) =>
              updateFormData({ ...formData, email_address: e.target.value })
            }
          />
          <FieldInput
            placeholder="Relationship to Tiwai"
            required
            onChange={(e) =>
              updateFormData({ ...formData, role: e.target.value })
            }
          />
          <TextArea
            placeholder="Tell Us Your Story"
            required
            onChange={(e) =>
              updateFormData({ ...formData, STORY: e.target.value })
            }
          />
          <Container width="100%" display="flex" justifyContent="flex-end">
            <SubmitButton type="submit">SUBMIT</SubmitButton>
          </Container>
        </StyledForm>
      )}
      {formSubmitted && (
        <Container display="flex" justifyContent="center" height="100%">
          <SuccessMsg>{successMessage}</SuccessMsg>
        </Container>
      )}
    </FormContainer>
  );
}

export default YourStoriesForm;
