import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Query } from "@graphql-types";
import Hero from "@components/shared/hero/hero";
import PeoplesGridDisplay from "@components/people/peoplesGridDisplay";
import SEO from "@components/shared/seo";
import YourStoriesCta from "@components/shared/yourStories/yourStoriesCta";

const HomePage = () => {
  const { sanityHomePage }: Query = useStaticQuery(graphql`
    {
      sanityHomePage {
        homeHero {
          heroHeader
          heroSubText {
            _rawContent
          }
          heroBackgroundImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                ...GatsbySanityImageFluid
              }
            }
          }
          heroMobileBackgroundImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                ...GatsbySanityImageFluid
              }
            }
          }
          useVideo
          heroVideo {
            id
            vimeoUrl
            uploadedVideo {
              asset {
                url
              }
            }
          }
          ctaScrollButtonText
        }
        yourStoriesBlock {
          _type
          buttonText
          ctaTextBlock {
            _rawContent
          }
          popUpText {
            _rawContent
          }
        }
        seoBlock {
          pageTitle
          slug {
            current
          }
          ogImage {
            asset {
              fluid {
                srcWebp
                srcSet
                srcSetWebp
                src
              }
            }
          }
          pageKeyWords
          pageDescription
        }
      }
    }
  `);

  if (sanityHomePage == null) {
    return null;
  }

  const { homeHero, seoBlock, yourStoriesBlock } = sanityHomePage;

  return (
    <>
      <SEO seoData={seoBlock} slug={seoBlock?.slug?.current ?? "/"} />
      <Hero
        heroContent={homeHero}
        headerIsVisible
        isHomePage
        gradientOpacity={0.5}
      />
      <PeoplesGridDisplay />
      {yourStoriesBlock && <YourStoriesCta content={yourStoriesBlock} />}
    </>
  );
};

export default HomePage;
