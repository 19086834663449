import React, { useContext, useState } from "react";
import { FluidObject } from "gatsby-image";
import styled from "styled-components";
import { Link } from "gatsby";

import { Container, H3, P } from "@util/standard";
import { AnimationContainer, IconButton, Image } from "@sub";
import {
  animationTypes,
  MOBILE_BREAKPOINT,
  colors,
  IPAD_PRO_BREAKPOINT,
} from "@util/constants";
import {
  SanityBlockContent,
  SanityImage,
  SanityLink,
  SanitySlug,
} from "@graphql-types";
import {
  PreviewContainer,
  StyledContainer,
} from "@components/shared/shared.styles";
import ExpandedTab from "@components/shared/expandedTab";
import ModalBox from "@components/shared/modalBox";
import { ScreenSizeContext } from "@components/shared/screenWidthProvider";
import PopUpContentBlock from "./sub/popUpContentBlock";

interface Props {
  name: string | undefined | null;
  description?: string | undefined | null;
  subText?: SanityBlockContent | null | undefined;
  role?: string | undefined | null;
  slug?: SanitySlug | undefined | null;
  featureImage: SanityImage | undefined | null;
  hasInnerPage?: boolean;
  ctaLink?: SanityLink | null | undefined;
  index?: number;
  displayStyle?: string | null | undefined;
  enableExpandedView?: boolean;
  disableExpandedVIew?: boolean;
  containImage?: boolean;
}

const TextContainer = styled(Container)<{ hoverEffect: boolean }>`
  position: absolute;
  z-index: 2;
  bottom: 30px;
  left: 30px;
  ${({ hoverEffect }) => hoverEffect && `bottom:60px `};
  transition: all 0.2s;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    bottom: 0px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    bottom: 0px;
    p {
      line-height: 18px;
    }
  }
`;

const OverLay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(
    179.29deg,
    rgba(15, 23, 45, 0) 39.68%,
    #060c1c 148.79%
  );
`;

function Preview(props: Props) {
  const {
    name,
    description,
    featureImage,
    slug,
    role,
    subText,
    displayStyle,
    ctaLink,
    index,
    enableExpandedView,
    disableExpandedVIew,
    containImage,
  } = props;

  const [hoverEffectOn, setHoverEffectOn] = useState(false);
  const [expandedViewOpen, setExpandedViewOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const { screenSize } = useContext(ScreenSizeContext);

  const handleClick = () => {
    setExpandedViewOpen(true);
    setModalVisible(true);
  };

  return (
    <Container position="relative">
      {expandedViewOpen && enableExpandedView && !disableExpandedVIew && (
        <>
          {screenSize.isMobileWidth && (
            <ModalBox visible={modalVisible} setVisible={setModalVisible}>
              <PopUpContentBlock
                name={name}
                subText={subText}
                index={index}
                featureImage={featureImage}
                displayStyle={displayStyle}
                ctaLink={ctaLink}
              />
            </ModalBox>
          )}
          {!screenSize.isMobileWidth && (
            <ExpandedTab
              name={name}
              subText={subText}
              index={index}
              featureImage={featureImage}
              displayStyle={displayStyle}
              ctaLink={ctaLink}
              containImage={containImage}
            >
              <IconButton
                buttonText="close"
                textColor={colors.dimgray}
                darkVersion={displayStyle === "light" || displayStyle == null}
                reversed
                icon="closeIcon"
                onClick={() => setExpandedViewOpen(false)}
              />
            </ExpandedTab>
          )}
        </>
      )}
      <AnimationContainer animationType={animationTypes.slideInBottom}>
        <PreviewContainer
          onMouseEnter={() => setHoverEffectOn(true)}
          onMouseLeave={() => setHoverEffectOn(false)}
          onClick={() => handleClick()}
          imacHeight="85vh"
          height="80vh"
        >
          <Image
            isBackground
            backgroundStyle={{
              width: "100%",
              height: "100%",
            }}
            fluid={featureImage?.asset?.fluid as FluidObject}
            objectFit="contain"
          />
          <TextContainer hoverEffect={hoverEffectOn}>
            <H3
              color="white"
              margin="0px 0px -5px 0px"
              fontSize={30}
              fontFamily="extra-bold"
              ipadProWidth="min-content"
              tabletWidth="100%"
            >
              {name?.toUpperCase()}
            </H3>
            <P
              fontSize={17}
              tabletFontSize={15}
              fontFamily="regular"
              width="72%"
              maxWidth="310px"
            >
              {role}
            </P>
            <StyledContainer hoverEffect={hoverEffectOn}>
              {description && (
                <P className="descriptionP" fontFamily="regular" fontSize={14}>
                  {description}
                </P>
              )}
              <IconButton
                buttonText="MORE"
                margin="1.2em 0 0 0"
                imgHeight={15}
                imgWidth={15}
                url={slug ? `people/${slug?.current}` : null}
                onClick={
                  enableExpandedView
                    ? () => setExpandedViewOpen(true)
                    : () => null
                }
              />
            </StyledContainer>
          </TextContainer>
          <Link to={enableExpandedView ? null : `people/${slug?.current}`}>
            <OverLay></OverLay>
          </Link>
        </PreviewContainer>
      </AnimationContainer>
    </Container>
  );
}

export default Preview;
